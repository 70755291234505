import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root{
        --teslaColor : #393c41;
        scroll-behavior: smooth;
    }
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family:"Rubik" , sans-serif;
        color: #393c41;
    }
    body{
        width: 100vw;
        overflow-x: hidden;
        scroll-behavior: smooth !important;
    }
    html{
        scroll-behavior: smooth !important;
    }

`;

export default GlobalStyle;
