import React, { useState } from "react";
import { Wrapper, Content, Content1 } from "./header.style";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import SideNav from "../SideNav";
import CloseIcon from "@mui/icons-material/Close";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Wrapper>
        <div className="logo">
          <Link style={{ textDecoration: "none" }} to="/">
            <img src="/images/Logo.webp" alt="Site logo" />
          </Link>
        </div>
        <Content>
          <HashLink to="/#home">Home</HashLink>
          <br />
          <HashLink to="/#about">About</HashLink>
          <br />
          <HashLink to="/#contact">Contact us</HashLink>
          <br />
          <HashLink to="/gallery">Gallery</HashLink>
          <br />
          <Link to="" onClick={() => setOpen(true)}>
            Services
          </Link>
        </Content>
        <Content1>
          <Link to="" onClick={() => setOpen(true)}>
            Menu
          </Link>
        </Content1>
        {open && (
          <div className="top">
            <div className="close" onClick={() => setOpen(false)}>
              <CloseIcon />
            </div>
          </div>
        )}
        <SideNav show={open} setState={(e) => setOpen(e)} />
      </Wrapper>
    </>
  );
};

export default Header;
