import { userId } from "./mailkey";
import emailjs from "emailjs-com";

export const sendMail = ({ userName, email, message }) => {
  emailjs
    .send(
      "service_9d65aah",
      "template_xywcdtp",
      {
        userName: userName,
        message: message,
        email: email,
      },
      userId
    )
    .then((error) => {
      console.log(error.text);
    });
};
