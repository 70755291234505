import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Section from "../Section";
import ContactForm from "../ContactForm";
import { Wrapper, Content } from "../home.style";
import { Fade } from "react-slideshow-image";
import { images } from "./SlideImages";
import "react-slideshow-image/dist/styles.css";

const Slideshow = () => {
  return (
    <>
      <Header />
      <Wrapper>
        <Content>
          <div className="slide-container">
            <Fade>
              {images.map((fadeImage, index) => (
                <div
                  style={{
                    width: "100vw",
                    height: "100vh",
                    paddingTop: "60px",
                  }}
                  key={index}
                >
                  <img
                    style={{
                      display: "block",
                      height: "80%",
                      maxWidth: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={fadeImage.image}
                    alt={fadeImage.title}
                  />
                  <h2>{fadeImage.title}</h2>
                </div>
              ))}
            </Fade>
          </div>
        </Content>
        <Content>
          <Content id="contact" style={{ height: "98vh" }}>
            <Section
              title="Contact us"
              desc="We are here to answer all your queries"
              form={<ContactForm />}
            />
          </Content>
          <Content>
            <Footer />
          </Content>
        </Content>
      </Wrapper>
    </>
  );
};

export default Slideshow;
