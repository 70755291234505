import React from "react";
import { Wrapper, Content } from "./services.style";
import Section from "../Section";
import Header from "../Header";
import Footer from "../Footer";
import ContactForm from "../ContactForm";

const Services = ({ data: { description, image, name } }) => {
  document.title = `Simons VRS | ${name}`;
  document.getElementsByTagName("meta")[
    "description"
  ].content = `${name} engineers and repairs`;
  return (
    <>
      <Header />
      <Wrapper>
        <Content id="services">
          <Section
            style={{ whiteSpace: "pre-line" }}
            title={name}
            images={image}
            bigDesc={description}
            desc=""
            sideDesc={description}
            bottomDesc=""
          />
        </Content>
        <Content id="contact" style={{ height: "100dvh" }}>
          <Section
            title="Contact us"
            desc="We are here to answer all your queries"
            form={<ContactForm />}
          />
        </Content>
        <Content>
          <Footer />
        </Content>
      </Wrapper>
    </>
  );
};

export default Services;
