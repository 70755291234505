import React, { useEffect } from "react";
import { Wrapper, Content } from "./home.style";

import ReactGA from "react-ga4";
import Section from "./Section";
import Header from "./Header";
import Footer from "./Footer";
import ContactForm from "./ContactForm";
import checkScreenSize from "../Utils/checkScreenSize";

function Home() {
  useEffect(() => {
    ReactGA.initialize("G-T6FNZKTBD4");
  }, []);
  checkScreenSize();
  document.title = " Simons VRS | Home ";
  document.getElementsByTagName("meta")[
    "description"
  ].content = `Calorex, Swiming pool repair and Air conditioning specialists`;
  return (
    <>
      <Header />
      <Wrapper>
        <Content id="home">
          <Section
            title="Specialists in the management and operation of swimming pool ventilation and heat pump systems"
            desc="Simons-VRS pride ourselves on our diverse knowledge of the industry, with a focus on energy saving solutions for all clientele"
            mobile="true"
            backgroundImg="pool.webp"
            bottomDesc="Coverage to London & Greater London area"
            link=""
            rightbtn="Services"
            leftbtn="Contact us"
          />
        </Content>
        <Content id="services">
          <Section
            className="section"
            title="Services | Products | Installations"
            backgroundImg=""
            desc="Vast range of services available at your doorstep"
            bigDesc=""
            bottomDesc="*Are you looking for something else? Please reach out and we will be happy to help you!"
            arrow="yes"
            servicesLeft={[
              {
                name: "Swimming Pool Ventilations",
                link: "swimmingPoolVentilation",
              },
              {
                name: "Dehumidification Systems",
                link: "dehumidificationSystems",
              },
              {
                name: "Swimming Pool Heat Pumps",
                link: "swimmingPoolAirSourceHeatPumps",
              },
            ]}
            servicesRight={[
              {
                name: "Air Handling Units",
                link: "airHandlingUnits",
              },
              {
                name: "Air Conditioning Units",
                link: "airConditioningUnits",
              },
              {
                name: "Services and Installations",
                link: "Services",
              },
            ]}
          />
        </Content>
        <Content id="about">
          <Section
            title="Read our story"
            backgroundImg=""
            desc="Simons Ventilation & Refrigeration Services are a certified Refcom member."
            bigDesc=<div>
              We are a team of HVAC engineers with extensive experience and
              expertise in swimming pool ventilation and dehumidification
              systems, based in Wandsworth, London. <br /> <br />
              Our services cover the London and Greater London areas. Led by
              director Shane Simons, our team brings over fifteen years of
              technical knowledge and industry experience. We have worked
              closely with Dantherm equipment (previously Calorex) on a range of
              projects, from small air source pumps in domestic properties to
              sophisticated air handling systems in commercial swimming pools
              and leisure parks. <br />
              <br />
              Our dedicated team of service engineers has earned a first-class
              reputation for quality and reliability. We are experienced in
              working with a wide range of industry products and equipment,
              including commercial and domestic air conditioning systems, ground
              source and air source heat pumps, chilled water systems, air
              handling/management systems, refrigeration dehumidifiers, fresh
              air dehumidifiers, and associated controls. <br />
              <br />
              In addition to our expertise in swimming pool ventilation and
              dehumidification systems, we have a broad range of experience in
              the HVAC industry. Our team is experienced in the installation,
              maintenance, and repair of commercial and domestic air
              conditioning systems, as well as ground source and air source heat
              pumps. <br />
              <br />
              We also have extensive experience with chilled water systems, air
              handling and management systems, refrigeration dehumidifiers, and
              fresh air dehumidifiers. <br />
              <br />
              At our core, we prioritize quality and reliability in all aspects
              of our work. Our team of service engineers is dedicated to
              ensuring that our clients receive the highest level of service, no
              matter the scope or complexity of the project. We are committed to
              providing cost-effective solutions that meet our clients' needs
              and exceed their expectations. <br />
              <br />
              We believe that ongoing education and training are essential to
              maintaining our competitive edge and staying at the forefront of
              the HVAC industry. As such, we regularly attend industry
              conferences, workshops, and training sessions to stay informed of
              the latest trends and technologies. This commitment to ongoing
              learning allows us to provide our clients with the most up-to-date
              solutions and services. <br />
              <br />
              Finally, we take pride in our professionalism and strong work
              ethic. We understand that every project is unique, and we work
              closely with our clients to ensure that their needs and goals are
              fully understood and met. <br />
              <br />
              From the initial consultation to project completion, we strive to
              maintain open and transparent communication, providing regular
              updates and progress reports. Our goal is to build long-term
              relationships with our clients based on trust, quality, and
              reliability.
            </div>
            bottomDesc=""
          />
        </Content>
        <Content id="contact" style={{ height: "100dvh" }}>
          <Section
            title="Contact us"
            backgroundImg=""
            desc="We are here to answer all your queries"
            form={<ContactForm />}
          />
        </Content>
        <Content>
          <Footer />
        </Content>
      </Wrapper>
    </>
  );
}

export default Home;
