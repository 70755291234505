import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .contactForm {
    display: flex;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    max-height: 100%;
    overflow: scroll;
    padding 4% 10% 4% 10%;
  }

  .sideItems {
    display: flex;
    text-align: center;
    width: 100%;
  }

  .name {
    margin: 0% 2% 3% 10%;
    width: 80%;
    height: 100%;
  }

  .email {
    margin: 0% 10% 3% 2%;
    width: 80%;
    height: 100%;
  }

  .number {
    margin: 0% 2% 3% 10%;
    width: 80%;
    height: 100%;
  }

  .company {
    margin: 0% 10% 3% 2%;
    width: 80%;
    height: 100%;
  }

  .message {
    margin: 0% 10% 5% 10%;
    width: 80%;
    height: 100%;
  }

  input {
    font-size: 1.2rem;
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 0.5px solid grey;
    padding: 5px 0px;
    outline: none;
    :focus {
      border-bottom: 1px solid blue;
    }
  }

  textarea {
    font-size: 1.2rem;
    padding: 5px;
    margin: auto;
    width: 100%;
    height: 300px;
    outline: none;
    resize: none;
    border-radius: 5px;
    border: 0.5px solid grey;
    padding: 15px 10px;
  }

  textarea:focus {
    border-color: none;
  }

  button {
    color: white;
    margin-top: 15%;
    padding: 12px 20px;
    width: 260px;
    border-radius: 20px;
    outline: none;
    border: none;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.6px;
    opacity: 0.9;
    background-color: rgba(23, 26, 32, 1);
    :hover {
      cursor: pointer;
    }
  }

  .greetingsMessage {
    margin-top: 40px;
  }

  @media screen and (max-width: 600px) {
    textarea {
      font-size: 1.2rem;
      padding: 5px;
      margin: 7% auto;
      width: 100%;
      height: auto;
      outline: none;
      resize: none;
      border-radius: 5px;
      border: 0.5px solid grey;
      padding: 15px 10px;
    }

    form {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    max-height: 100%;
    overflow: scroll;
    padding 4% 4% 4% 4%;
  }

    .name {
      margin: 10% 2% 3% 10%;
      width: 80%;
      height: 100%;
    }

    .email {
      margin: 10% 10% 3% 2%;
      width: 80%;
      height: 100%;
    }

    .number {
      margin: 5% 2% 10% 10%;
      width: 80%;
      height: 100%;
    }

    .company {
      margin: 5% 10% 10% 2%;
      width: 80%;
      height: 100%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 5px 18px;
    letter-spacing: 0.4px;
    color: rgba(59, 59, 59, 0.753);
    transition: color 200ms ease-in;
    :hover {
      color: black;
    }
  }

  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: center;
  }
`;
