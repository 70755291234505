export const swimmingPoolVentilation = {
  description: `Simons Ventilation & Refrigeration Services are experts in the maintenance of pool halls and pride ourselves on our diverse knowledge of the operation of equipment for all leading manufactures of swimming pool ventilation and dehumidification systems, including: Calorex, Heatstar, Dantherm, Weger Air Solutions, Recotherm, Menerga, Climatec.  
We offer pre-planned maintenance programs tailored to your requirements and usage level of the swimming pool, and can cover call-out and remedial works. 
`,
  name: "Swimming Pool Ventilations",
  image: "swimming-pool-ventilation.webp",
};

export const dehumidificationSystems = {
  description: `Dehumidification is vital in the maintenance of swimming pools, close control laboratories, storage units, vintage car storage, garages and much more. 
Our trained and experienced technicians offer an expert service, maintenance, and repair work for your dehumidifier, ensuring the optimal humidity levels are restored and maintained. 
`,
  name: "Dehumidification Systems",
  image: "pool-dehum-system.webp",
};

export const swimmingPoolAirSourceHeatPumps = {
  description: `Swimming pool heat pumps are an environmentally friendly and cost-effective way to heat your swimming pool. With their high heat production and low energy consumption, air source heat pumps are now the most common method for heating outdoor swimming pools in the UK. 
  We are pleased to offer maintenance plans, winterising and remedials on a wide range of swimming pool heat pumps. These include: include Calorex, Dream Heat Pumps, Thermotec Inverter, Duratech, Aquacal, BWT Mitubishi Inverter/Climexel, Sunspring, Garden Pac, and many more.`,
  name: "Swimming pool air source heat pumps",
  image: "swimming-pool-heat-pump.webp",
};

export const airHandlingUnits = {
  description: `Air Handling units (AHUs) are critical in purifying and maintaining optimal climate conditions within buildings or premises. AHUs come in various forms, ranging from basic units designed to maintain a common supply and extract air stream to a room or office floor, or to control temperatures and conditions of close proximity rooms. 
  
  Failed drive belts or blocked air filters are the most common faults we find with AHUs, however regular service visits will ensure optimal conditions are maintained throughout the operation of the AHU.
  
  Simons Ventilation & Refrigeration Services can offer pre-planned maintenance programs tailored to your requirements, cover call-out visits and carry out remedial works.`,
  name: "Air handling units",
  image: "air-handling-unit.webp",
};

export const airConditioningUnits = {
  description: `Air conditioning is now part of our everyday lives, from homes, public transport, office spaces, retail and dining outlets. 
  
  At Simons Ventilation & Refrigeration Services, our engineers are experts in the installation and maintenance of air conditioning units. 
  
  Our complete services include safe installation, comprehensive maintenance and swift remedial work for all customers.`,
  name: "Air conditioning units",
  image: "aircon.webp",
};

export const services = {
  description: `Simons Ventilation & Refrigeration Services recognise the need for quality after-sales care. We are pleased to offer a range of maintenance and service contracts for all swimming pool ventilation and dehumidification systems, air conditioning, heat pumps, air handling units and associated controls. Our service is available to all clients commercial or domestic, throughout London and the Home Counties. 
            
Our clients vary from leisure centres, hotels, fitness clubs, schools, hospitals, health spas and many private properties with indoor and outdoor swimming pools. We are not associated with any specific manufacturer, however do supply, install and maintain all leading manufactures of HVAC equipment. We liaise with consultants as well as end users to ensure the correct equipment for their specific application.  

We independently design, supply, and install swimming pool ventilation and dehumidification systems, air handling units, and air conditioning systems. We liaise with consultants and end users to determine energy efficient and practical solutions to suit all client requirements. We supply and build air handling units in flatpack form. 

In certain scenarios, it is not possible to install new or replace old air handling units due to limited access space.  We work closely with the manufacturers of both swimming pool dehumidification/ventilation units and air handling units to have the equipment supplied in flat-pack format. Our experienced team of engineers will then build the equipment on site.`,
  name: "Services and Installations",
  image: "",
};
