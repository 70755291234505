import React from "react";
import { Wrapper, Content } from "./home.style";
import Section from "./Section";
import Header from "./Header";

const NotFound = () => {
  document.title = " Simons VRS | Page not found ";
  return (
    <>
      <Header />
      <Wrapper>
        <Content>
          <Section title="Oops, page not found. Let's get you out of here!" />
        </Content>
      </Wrapper>
    </>
  );
};

export default NotFound;
