import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { Wrapper, ContentTop, ContentMid, Content } from "./section.style";
import checkScreenSize from "../../Utils/checkScreenSize";
import Slideshow from "../Slideshow/index";

function Section({
  title,
  mobile,
  desc,
  backgroundImg,
  link,
  leftbtn,
  rightbtn,
  arrow,
  bigDesc,
  images,
  sideDesc,
  bottomDesc,
  form,
  slideshow,
  servicesLeft,
  servicesRight,
}) {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  const descriptionWithImages = () => {
    if (bigDesc && !images) {
      return <div className="bigDesc">{bigDesc}</div>;
    } else if (bigDesc && images) {
      return (
        <div className="textWithImage">
          <img src={`/images/${images}`} alt={images} className="img" />
          <div>{sideDesc}</div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Wrapper bg={backgroundImg}>
      <ContentTop>
        {checkScreenSize() ? (
          <>
            <h2>{title}</h2>
            <p>{desc}</p>
          </>
        ) : (
          <h3>{title}</h3>
        )}
        {descriptionWithImages()}
        {slideshow && (
          <div>
            <Slideshow images={images} />
          </div>
        )}
        {servicesLeft && servicesRight && (
          <div className="listContainer">
            <div className="listItemsLeft">
              {servicesLeft.map((service, index) => {
                return (
                  <button
                    className={"listButtons"}
                    key={index}
                    onClick={() => routeChange(`/${service.link}`)}
                  >
                    <>{service.name}</>
                  </button>
                );
              })}
            </div>
            <div className="listItemsRight">
              {servicesRight.map((service, index) => {
                return (
                  <button
                    className={"listButtons"}
                    key={index}
                    onClick={() => routeChange(`/${service.link}`)}
                  >
                    <>{service.name}</>
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {form && (
          <div className="contactForm">
            <>{form}</>
          </div>
        )}
      </ContentTop>
      <div>
        <ContentMid className={leftbtn || rightbtn ? "buttons" : ""}>
          {leftbtn && (
            <Link className="left" to="/#contact">
              <button>{leftbtn}</button>
            </Link>
          )}
          {rightbtn && (
            <Link className="right" to="/#services">
              <button>{rightbtn}</button>
            </Link>
          )}
        </ContentMid>
        {bottomDesc && (
          <Content>
            <div className="Info-bar">
              <div className="Specs">
                <p>{bottomDesc}</p>
              </div>
            </div>
          </Content>
        )}
      </div>
    </Wrapper>
  );
}

export default Section;
