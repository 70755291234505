import React, { useEffect } from "react";
import { Wrapper, Content } from "./footer.style";
import ReactGA from "react-ga4";

const Footer = ({ bottom }) => {
  useEffect(() => {
    ReactGA.initialize("G-T6FNZKTBD4");
  }, []);

  const onClickTag = (action) => {
    ReactGA.event({
      category: "contact",
      action: action,
      label: "User clicked on footer",
    });
  };

  return (
    <Wrapper>
      <Content className={bottom}>
        <div>
          <a href="http://maps.google.com/?q=1a Beira Street, London, SW12 9LJ">
            1a Beira Street, London, SW12 9LJ
          </a>
        </div>
        <div>
          <a href="tel:0203 6000 193" onClick={onClickTag("phone_conversion")}>
            Phone: 0203 6000 193
          </a>
        </div>
        <div>
          <a
            href="mailto:info@simonsvrs.co.uk"
            subject="Systems query"
            target="_blank"
            rel="noreferrer"
            onClick={onClickTag("email_click")}
          >
            Send us an email
          </a>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Footer;
