import React, { useState, useEffect } from "react";
import { Wrapper, Content } from "./contactForm.style";
import { sendMail } from "../../Utils/sendMail";
import ReactGA from "react-ga4";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formFields, setFormFields] = useState({
    userName: "",
    email: "",
    number: "",
    company: "",
    message: "",
  });

  useEffect(() => {
    ReactGA.initialize("G-T6FNZKTBD4");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMail(formFields);
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  if (submitted) {
    return (
      <Wrapper>
        <Content>
          <div className="greetingsMessage">
            Thank you! We'll be in touch soon.
          </div>
        </Content>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <div className="sideItems">
          <div className="name">
            <input
              onChange={handleChange}
              value={formFields.userName}
              type="text"
              placeholder="Full name*"
              name="userName"
              required
            />
          </div>
          <div className="email">
            <input
              onChange={handleChange}
              value={formFields.email}
              type="email"
              placeholder="Email*"
              name="email"
              required
            />
          </div>
        </div>
        <div className="sideItems">
          <div className="number">
            <input
              onChange={handleChange}
              value={formFields.number}
              type="text"
              placeholder="Phone number"
              name="number"
            />
          </div>
          <div className="company">
            <input
              onChange={handleChange}
              value={formFields.company}
              type="text"
              placeholder="Company"
              name="company"
            />
          </div>
        </div>
        <div className="message">
          <textarea
            onChange={handleChange}
            value={formFields.message}
            placeholder="Your message here*"
            name="message"
            required
          />
        </div>
        <div className="right">
          <button className="" type="submit">
            Send message
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default ContactForm;
