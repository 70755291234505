import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url('/images/${props.bg}')`};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  img {
    margin-top: 15px;
    margin-bottom: 10px;
    height: 50px;
    animation: animate infinite 1.8s;
  }
  .arrow {
    display: flex;
    justify-content: center;
  }

  .buttons {
    margin-bottom: 20px;
    display: flex;
  }

  @keyframes animate {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(5px);
    }
    60% {
      transform: translateY(3px);
    }
  }
  @media screen and (max-width: 600px) {
    img {
      height: 40px;
    }
  }
`;

export const ContentTop = styled.div`
  text-align: center;
  padding-top: 60px;
  h2 {
        font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 2.5rem;
    color: var(--teslaColor);
  }
  h3 {
    font-weight: 280;
    font-size: 2.0rem;
    color: var(--teslaColor);
  }
  p {
    font-size: 1.0rem;
    padding: 5px;
    color: rgba(59, 59, 59, 0.753);
  }
  a {
    text-decoration: underline;
    text-underline-position: under;
    display: inline-block;
    color: rgba(59, 59, 59, 0.753);
    transition: color 200ms ease-in;

    :hover {
      color: var(--teslaColor);
      text-decoration-thickness: 2px;
    }
  }

  button {
    height: 55px;
    padding: 12px 20px;
    margin: 2%;
    width: 260px;
    border-radius: 20px;
    outline: none;
    border: none;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.6px;
    opacity: 0.9;
    :hover {
      cursor: pointer;
    }
  }

  .listContainer {
    display: flex;
    height: 100%;
    max-height: 100%;
    padding: 5%;
    margin: 10%;
  }

  .listItemsLeft {
    display: flex;
    flex-direction: column;
    margin: auto 1% auto auto;
  }

  .listItemsRight {
    display: flex;
    flex-direction: column;
    margin: auto auto auto 1%;
  }

  .left button {
    background-color: rgba(23, 26, 32, 1);
    margin-right: 25px;
  }

  .bigDesc {
    justify-content:center;
    align-items:center;
    text-justify: auto;
    text-align: justify;
    margin-top: 6%;
    padding: 0% 20% 0% 20%;
    font-size: 20px;
    line-height: 1.6;
    overflow: scroll;
    height: 70vh;
    white-space: pre-line;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    hyphens: auto;

    @media screen and (max-width: 600px) {
      padding: 0% 10% 0% 10%;
      font-size: 1rem;
      justify-content:center;
      align-items:center;
      text-justify: auto;
      text-align: justify;
      margin-top: 6%;
      margin-bottom: 6%;
      line-height: 1.6;
      overflow: scroll;
      height: 80vh;
      white-space: pre-line;
    }
  }

  .textWithImage {
    justify-content:center;
    align-items:center;
    text-justify: distribute;
    text-align: justify;
    margin-top: 6%;
    padding: 0% 15% 0% 15%;
    overflow: scroll;
    height: 70vh;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    white-space: pre-line;
    font-size: 20px;
    justify: start;
    line-height: 1.6;

    img {
      height: 50%;
      float: left;
      margin: 1vh 15px 0 0;
      animation: none; !important;
    }
  
    @media screen and (max-width: 600px) {
    padding: 0% 10% 0% 10%;
    font-size: 1rem;
    justify-content:center;
    align-items:center;
    text-justify: auto;
    text-align: justify;
    margin-top: 6%;
    margin-bottom: 6%;
    line-height: 1.6;
    overflow: scroll;
    height: 75vh;
    white-space: pre-line;

    img {
      height: auto;
      width: 100%;
      margin: 0 0 2vh 0;
      }
    }
  }

  animation: transform 1s;
  animation-timing-function: ease-in;
  @keyframes transform {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    height:70vh;
    width: 100%;

   .listContainer {
    overflow: scroll;
    padding: 5%;
    margin: 0%;
    display: flex;
    flex-direction: column;
    }

  .listItemsLeft {
    height:auto;
    margin: auto auto 0 auto;
  }

  .listItemsRight {
    height:auto;
    margin: 0 auto auto auto;
  }

  }
`;

export const ContentMid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: white;
  }

  button {
    padding: 12px 20px;
    width: 260px;
    border-radius: 20px;
    outline: none;
    border: none;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.6px;
    opacity: 0.9;
    :hover {
      cursor: pointer;
    }
  }

  .left button {
    background-color: rgba(23, 26, 32, 1);
    margin-right: 25px;
    color: antiquewhite;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    .left,
    .right {
      display: flex;
      justify-content: center;
      height: 48px;
      margin-bottom: 15px;
    }
    .left button {
      margin-right: 0;
      height: 48px;
    }
    button {
      width: 90%;
      // padding: 5px 10px;
    }
  }

  animation: transform 1s;
  animation-timing-function: ease-in;
  @keyframes transform {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  .Info-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    h3 {
      color: white;
      font-weight: 400;
      font-size: 1.8rem;
      letter-spacing: 0.6px;
    }
    p {
      padding-top: 8px;
      color: white;
      font-weight: 200;
      font-size: 0.8rem;
      letter-spacing: 0.3px;
      color: black;
    }
    button {
      outline: none;
      border: white solid 4px;
      border-radius: 20px;
      padding: 8px 55px;
      background-color: transparent;
      color: white;
      transition: all 300ms ease-in;
      cursor: pointer;
      :hover {
        background-color: white;
        color: #141414;
      }
    }

    @media screen and (max-width: 600px) {
      .hp {
        display: none;
      }
      .order_btn {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
      }
      button {
        width: 90%;
        padding: 10px auto;
      }
      .Specs {
        margin: 10px 12px;
      }
      h3 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.7rem;
      }
    }
  }
  .Specs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 40px;
  }

  animation: transform 1s;
  animation-timing-function: ease-in;
  @keyframes transform {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`;
