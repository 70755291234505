const airconditioning = {
  title: "Air conditioning installation",
  image: require("./air-conditioning.webp"),
  description: ``,
};

const airconditioningservice = {
  title: "Air conditioning installation",
  image: require("./air-conditioning-service.webp"),
  description: ``,
};

const calorex = {
  title: "Calorex air source heat pump",
  image: require("./calorex.webp"),
  description: "",
};

const calorex2 = {
  title: "Calorex Vaporex 33",
  image: require("./calorex2.webp"),
  description: "",
};

const calorex3 = {
  title: "Calorex dehumidifier",
  image: require("./calorex3.webp"),
  description: "",
};

const calorex4 = {
  title: "Calorex delta rebuild",
  image: require("./calorex4.webp"),
  description: "",
};

const calorex5 = {
  title: "Calorex delta rebuild",
  image: require("./calorex5.webp"),
  description: "",
};

const calorex6 = {
  title: "Calorex Variheat AW900",
  image: require("./calorex6.webp"),
  description: "",
};

const calorex7 = {
  title: "Calorex Variheat AW600",
  image: require("./calorex7.webp"),
  description: "",
};

const dantherm = {
  title: "Weger air handling unit rebuild ",
  image: require("./dantherm.webp"),
  description: ``,
};

const heatstar = {
  title: "Heatstar Phoenix",
  image: require("./heatstar.webp"),
  description: ``,
};

const heatstar2 = {
  title: "Heatstar control panel ",
  image: require("./heatstar2.webp"),
  description: "",
};

const heatstar3 = {
  title: "Heatstar swimming pool ",
  image: require("./heatstar3.webp"),
  description: "",
};

const heatstar4 = {
  title: "Heatstar Phoenix, swimming pool",
  image: require("./heatstar4.webp"),
  description: "",
};

const heatstar5 = {
  title: "Heatstar swimming pool  ",
  image: require("./heatstar5.webp"),
  description: "",
};

const recotherm = {
  title: "Recotherm swimming pool ",
  image: require("./recotherm.webp"),
  description: "",
};

const recotherm2 = {
  title: "Recotherm swimming pool  ",
  image: require("./recotherm2.webp"),
  description: "",
};

const variheat = {
  title: " Calorex Variheat AW600",
  image: require("./variheat.webp"),
  description: ``,
};

const ves = {
  title: "VES Andover control panel ",
  image: require("./ves.webp"),
  description: "",
};

const weger = {
  title: "Weger air handling unit",
  image: require("./weger.webp"),
  description: ``,
};

export const images = [
  calorex,
  calorex2,
  calorex3,
  calorex4,
  calorex5,
  calorex6,
  calorex7,
  dantherm,
  heatstar,
  heatstar2,
  heatstar3,
  heatstar4,
  heatstar5,
  recotherm,
  recotherm2,
  variheat,
  ves,
  weger,
  airconditioning,
  airconditioningservice,
];
