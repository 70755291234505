import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  swimmingPoolVentilation,
  dehumidificationSystems,
  swimmingPoolAirSourceHeatPumps,
  airHandlingUnits,
  airConditioningUnits,
  services,
} from "./components/Services/serviceInfo";

// Components
import GlobalStyle from "./globalstyles";
import Home from "./components/home";
import Services from "./components/Services/";
import NotFound from "./components/404";
import { images } from "./components/Slideshow/SlideImages";
const Slideshow = React.lazy(() => import("./components/Slideshow/"));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<p> Loading...</p>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/swimmingPoolVentilation"
              element={<Services data={swimmingPoolVentilation} />}
            />
            <Route
              path="/dehumidificationSystems"
              element={<Services data={dehumidificationSystems} />}
            />
            <Route
              path="/swimmingPoolAirSourceHeatPumps"
              element={<Services data={swimmingPoolAirSourceHeatPumps} />}
            />
            <Route
              path="/airHandlingUnits"
              element={<Services data={airHandlingUnits} />}
            />
            <Route
              path="/airConditioningUnits"
              element={<Services data={airConditioningUnits} />}
            />
            <Route path="/services" element={<Services data={services} />} />
            <Route path="/gallery" element={<Slideshow images={images} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <GlobalStyle />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
