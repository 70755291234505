import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Wrapper, Content } from "./sidenav.style";

function SideNav({ show, setState }) {
  const handleClick = () => {
    setState(false);
  };

  var width = window.matchMedia("(max-device-width: 960px)").matches;

  return (
    <Wrapper show={show}>
      {!width ? (
        <Content>
          <ul>
            <li>
              <Link to="/swimmingPoolVentilation" onClick={() => handleClick()}>
                Swiming Pool ventilation
              </Link>
            </li>
            <li>
              <Link to="/dehumidificationSystems" onClick={() => handleClick()}>
                Dehumidification systems
              </Link>
            </li>
            <li>
              <Link
                to="/swimmingPoolAirSourceHeatPumps"
                onClick={() => handleClick()}
              >
                Swimming pool air source heat pumps
              </Link>
            </li>
            <li>
              <Link to="/airHandlingUnits" onClick={() => handleClick()}>
                Air handling units
              </Link>
            </li>
            <li>
              <Link to="/airConditioningUnits" onClick={() => handleClick()}>
                Air conditioning
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={() => handleClick()}>
                Services and Installations
              </Link>
            </li>
          </ul>
        </Content>
      ) : (
        <Content>
          <ul>
            <li>
              <HashLink to="/#home" onClick={() => handleClick()}>
                Home
              </HashLink>
            </li>
            <li>
              <HashLink to="/#about" onClick={() => handleClick()}>
                About
              </HashLink>
            </li>
            <li>
              <HashLink to="/#services" onClick={() => handleClick()}>
                Services
              </HashLink>
            </li>
            <li>
              <HashLink to="/#contact" onClick={() => handleClick()}>
                Contact us
              </HashLink>
            </li>
            <li>
              <HashLink to="/gallery" onClick={() => handleClick()}>
                Gallery
              </HashLink>
            </li>
          </ul>
        </Content>
      )}
    </Wrapper>
  );
}

export default SideNav;
